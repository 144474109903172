import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'dpl/shared/utils';
import CircularLinkedMap from 'dpl/shared/utils/CircularLinkedMap';
import FancyDropdownContext from 'dpl/components/FancyDropdown/context';
import FancyDropdownToggle from 'dpl/components/FancyDropdown/FancyDropdownToggle';
import FancyDropdownMenu from 'dpl/components/FancyDropdown/FancyDropdownMenu';
import FancyDropdownMenuItem from 'dpl/components/FancyDropdown/FancyDropdownMenuItem';
import FancyDropdownListItem from 'dpl/components/FancyDropdown/FancyDropdownListItem';

export default class FancyDropdown extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    allowPropagationOnClose: PropTypes.bool,
    closeOnItemClick: PropTypes.bool,
    selectOnOpen: PropTypes.bool,
    closeOnMouseLeave: PropTypes.bool,
    menuPosition: PropTypes.oneOf(['right', 'left']),
    menuWidth: PropTypes.string,
    id: PropTypes.string,
    closeOnClickOutside: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => {},
    isOpen: false,
    allowPropagationOnClose: false,
    closeOnItemClick: true,
    selectOnOpen: true,
    closeOnMouseLeave: false,
    menuPosition: 'left',
    menuWidth: null,
    id: 'FancyDropdown',
    closeOnClickOutside: true
  };

  state = {
    isOpen: this.props.isOpen,
    selectedMenuItemKey: ''
  };

  menuItemKeys = new CircularLinkedMap();

  ref = createRef();

  menuRef = createRef();

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isOpen !== this.props.isOpen &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.toggleOpen(nextProps.isOpen);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;
    if (prevState.isOpen !== isOpen) {
      this.props.onChange(isOpen);
      if (isOpen) {
        this.bindKeyHandlers();
      } else {
        this.unbindKeyHandlers();
      }
    }
  }

  componentWillUnmount() {
    this.unbindKeyHandlers();
  }

  setFirstMenuItemKey = debounce(() => {
    if (this.menuItemKeys.firstNode && this.props.selectOnOpen) {
      this.setSelectedMenuItemKey(this.menuItemKeys.firstNode.key);
    }
  });

  bindKeyHandlers() {
    window.addEventListener('keydown', this.handleKeyDown);
    document.body.addEventListener('click', this.handleClick);
  }

  unbindKeyHandlers() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.body.removeEventListener('click', this.handleClick);
  }

  get nextMenuItemKey() {
    const { selectedMenuItemKey } = this.state;
    return selectedMenuItemKey
      ? this.menuItemKeys.get(selectedMenuItemKey).next.key
      : null;
  }

  get previousMenuItemKey() {
    const { selectedMenuItemKey } = this.state;
    return selectedMenuItemKey
      ? this.menuItemKeys.get(selectedMenuItemKey).previous.key
      : null;
  }

  refreshMenuItemKeyPosition = itemKey => {
    this.menuItemKeys.remove(itemKey);
    this.menuItemKeys.set(itemKey);
  };

  addMenuItemKey = itemKey => {
    this.menuItemKeys.set(itemKey);
    this.setFirstMenuItemKey();
  };

  removeMenuItemKey = itemKey => {
    this.menuItemKeys.remove(itemKey);
    if (this.menuItemKeys.isEmpty) {
      this.setSelectedMenuItemKey(null);
    } else {
      this.setFirstMenuItemKey();
    }
  };

  setSelectedMenuItemKey = key => {
    this.setState({ selectedMenuItemKey: key });
  };

  toggleOpen = (forceState = !this.state.isOpen) => {
    this.setState({ isOpen: forceState });
  };

  handleClick = e => {
    if (
      !this.ref.current.contains(e.target) &&
      this.props.closeOnClickOutside
    ) {
      this.toggleOpen(false);
      if (!this.props.allowPropagationOnClose) {
        // don't do anything else but close the menu
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  handleKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        this.setSelectedMenuItemKey(this.nextMenuItemKey);
        break;
      }

      case 'ArrowUp': {
        e.preventDefault();
        this.setSelectedMenuItemKey(this.previousMenuItemKey);
        break;
      }

      case 'Escape': {
        e.preventDefault();
        this.toggleOpen(false);
        break;
      }

      default:
        break;
    }
  };

  get providerValue() {
    return {
      ...this.state,
      toggleOpen: this.toggleOpen,
      shouldCloseOnItemClick: this.props.closeOnItemClick,
      setSelectedMenuItemKey: this.setSelectedMenuItemKey,
      addMenuItemKey: this.addMenuItemKey,
      removeMenuItemKey: this.removeMenuItemKey,
      refreshMenuItemKeyPosition: this.refreshMenuItemKeyPosition,
      menuRef: this.menuRef,
      positionAlignment: this.props.menuPosition,
      menuWidth: this.props.menuWidth
    };
  }

  render() {
    const { closeOnMouseLeave } = this.props;

    return (
      <div
        className="FancyDropdown relative"
        ref={this.ref}
        onMouseLeave={closeOnMouseLeave ? () => this.toggleOpen(false) : null}
        id={this.props.id ?? 'FancyDropdown'}
        data-test-id={this.props.id ?? 'FancyDropdown'}
      >
        <FancyDropdownContext.Provider value={this.providerValue}>
          {this.props.children}
        </FancyDropdownContext.Provider>
      </div>
    );
  }
}

export {
  FancyDropdownToggle,
  FancyDropdownMenu,
  FancyDropdownMenuItem,
  FancyDropdownListItem
};
